



























































































































































import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Home extends Vue {
    private scrollTo(id: string) {
        (document.getElementById(id) as HTMLDivElement).scrollIntoView({
            behavior: 'smooth',
        });
    }
}
