import { render, staticRenderFns } from "./App.vue?vue&type=template&id=6caed2e8&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./appLocale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fmister-j%2Fhomepage%2Fsrc%2FApp.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports