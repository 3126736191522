import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import Buefy from 'buefy';
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages: require('./globalLocale.json'),
  silentTranslationWarn: true,
});

const app = new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  i18n,
}).$mount('#app');

(window as any).app = app;

export default app;
