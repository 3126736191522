import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },

        {
            path: '/Imprint',
            name: 'Imprint',
            component: () => import(/* webpackChunkName: "Imprint" */ './views/Imprint.vue'),
        },

        {
            path: '/Privacy-Policy',
            name: 'Privacy-Policy',
            component: () => import(/* webpackChunkName: "Privacy-Policy" */ './views/PrivacyPolicy.vue'),
        },
    ],
});
