












































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class LocaleSelector extends Vue {
    private changeLocale(locale: string) {
        this.$root.$i18n.locale = locale;
    }

    private get locale() {
        return this.$root.$i18n.locale;
    }
}
