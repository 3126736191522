<i18n src="./appLocale.json"></i18n>

<template>
    <div id="app">
        <router-view style="min-height: 100vh;"></router-view>

        <LocaleSelector id="locale-selector"></LocaleSelector>

        <footer class="footer has-background-grey-darker"
                style="padding-bottom: 1rem; padding-top: 1rem"
                id="Footer"
        >
            <div class="content has-text-centered">
                <strong>Mister J</strong> by <a href="https://janjaap.de" target="_blank">JanJaap WebSolutions</a><br>
                <small>
                    <router-link to="/Imprint" target="_blank">{{$t('imprint')}}</router-link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link to="/Privacy-Policy" target="_blank">{{$t('privacyPolicy')}}</router-link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="https://janjaap.de/contact" target="_blank">{{$t('contact')}}</a>
                </small>
            </div>
        </footer>
    </div>
</template>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Expletus+Sans');
    // Import Bulma's core
    @import "~bulma/sass/utilities/_all";

    $navbar-breakpoint: 700px;

    $primary: #6A00FF;
    $primary-invert: findColorInvert($primary);

    $info: #1158FF;
    $info-invert: findColorInvert($info);

    $warning: #FFCA33;
    $warning-invert: findColorInvert($warning);

    $danger: #D80006;
    $danger-invert: findColorInvert($danger);

    $success: #4DA328;
    $success-invert: findColorInvert($success);

    // Setup $colors to use as bulma classes (e.g. 'is-twitter')
    $colors: (
            "white": ($white, $black),
            "black": ($black, $white),
            "light": ($light, $light-invert),
            "dark": ($dark, $dark-invert),
            "primary": ($primary, $primary-invert),
            "info": ($info, $info-invert),
            "success": ($success, $success-invert),
            "warning": ($warning, $warning-invert),
            "danger": ($danger, $danger-invert),
    );

    // Links
    $link: $primary;
    $link-invert: $primary-invert;
    $link-focus-border: $primary;

    @import "~bulma";
    @import "~buefy/src/scss/buefy";

    #Footer,
    #Footer a,
    #Footer strong {
        color: whitesmoke;
    }

    #Footer a:hover {
        text-decoration: underline;
        color: whitesmoke;
    }

    #locale-selector {
        position: fixed;
        bottom: 2rem;
        left: 2rem;
    }

    @media screen and (max-width: 600px) {
        #locale-selector {
            bottom: auto;
            left: auto;
            right: 0.5rem;
            top: 0.5rem;
        }
    }
</style>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import LocaleSelector from './components/LocaleSelector';
    @Component({
        components: {LocaleSelector},
    })
    export default class App extends Vue {
    }
</script>
